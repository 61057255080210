import React, { useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import { getHomePageSections } from "../../API/HomePageSectionAPI";
import Geocode from "react-geocode";
import BottomBar from "../../MobileLayout/BottomBar";
import useIsMobile from "../../customHooks/useIsMobile";
import FoodCatGallerySkeleton from "../../skelton/FoodCatGallerySkeleton";
import DairySkeleton from "../../skelton/DairySkeleton";
import OrderFoodCatSkeleton from "../../skelton/OrderFoodCatSkeleton";

// Lazy load components
const CardSlider = React.lazy(() => import("../ChildComponents/CardSlider"));
const CountryBaseFoodCategories = React.lazy(() => import("../ChildComponents/CountryBaseFoodCategories"));
const OrderFoodCat = React.lazy(() => import("../ChildComponents/OrderFoodCat"));
const FoodCatGallery = React.lazy(() => import("../ChildComponents/FoodCatGallery"));
const BrowseByKitchen = React.lazy(() => import("../ChildComponents/BrowseByKitchen"));
const FoodNameCategory = React.lazy(() => import("../ChildComponents/FoodNameCategory"));
const Dairy = React.lazy(() => import("../ChildComponents/Dairy"));
const Circle_2 = React.lazy(() => import("../ChildComponents/Circle_2"));
const HowWeDo = React.lazy(() => import("../ChildComponents/HowWeDo"));
const Receipebook_layoutTwo = React.lazy(() => import("../ChildComponents/Receipebook_layoutTwo"));
const HomePageStyleOne = React.lazy(() => import("../ChildComponents/HomePageStyleOne"));
const HomePageStyleTwo = React.lazy(() => import("../ChildComponents/HomePageStyleTwo"));

const HomeComponent = () => {
  Geocode.setApiKey("YOUR_GOOGLE_MAPS_API_KEY");
  Geocode.setLanguage("en");
  Geocode.setRegion("BD");
  Geocode.setLocationType("ROOFTOP");

  const isMobile = useIsMobile();

  useEffect(() => {
    getHomePageSections();
  }, []);

  let HomePageList = useSelector((state) => state.homePage.homePageList);

  return (
    <section>
      <div className="Space__For__Header"></div>

      <Suspense fallback={<div>Loading...</div>}>
        <CountryBaseFoodCategories />
        <CardSlider />

        {HomePageList.map((item, index) => {
          switch (item.sectionStyle) {
            case "Style1":
              return <HomePageStyleOne key={index} data={item} />;
            case "Style2":
              return <HomePageStyleTwo key={index} data={item} />;
            case "Style3":
              return <Circle_2 key={index} data={item} />;
            case "Style4":
              return <OrderFoodCat key={index} data={item} />;
            case "Style5":
              return <FoodCatGallery key={index} data={item} />;
            case "Style6":
              return <BrowseByKitchen key={index} data={item} />;
            case "Style7":
              return <HowWeDo key={index} data={item} />;
            case "Style9":
              return <FoodNameCategory key={index} data={item} />;
            case "Style10":
              return <Dairy key={index} data={item} />;
            case "Style11":
              return <Receipebook_layoutTwo key={index} data={item} />;
            default:
              return null;
          }
        })}
      </Suspense>
{/* <FoodCatGallerySkeleton/>
<DairySkeleton/>
<OrderFoodCatSkeleton/> */}
      {isMobile && <BottomBar />}
    </section>
  );
};

export default HomeComponent;
