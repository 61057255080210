import React, { useState } from "react";
import { useEffect } from "react";
import { GetFoodBySellerAPI, GetSellerAPI } from "../../../API/SellerAPI";
import { useSelector } from "react-redux";
import FoodPresentationCard from "../../Common/Cards/FoodPresentation/FoodPresentationCard";
import { GetRecipeByUserAPI } from "../../../API/RecipeAPI";
import { getUserDetails } from "../../../Helper/SessionHelper";
import RecipeCard from "../../Common/Cards/RecipeCard/RecipeCard";
import { getFoodDiaryByUserAPI } from "../../../API/FoodDiaryAPI";
import DiaryCard from "../../Common/Cards/DiaryCard/DiaryCard";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import SliderProvider from "../../Common/Slider/SliderProvider";

const AllFoodSeller = () => {
  const { userId } = useOutletContext();
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const userId1 = searchParams.get("userId");
  useEffect(() => {
    GetSellerAPI(id);
  }, [id]);
  const sellerData = useSelector((state) => state.seller.getSellerInfo);
  const userIdbySeller = sellerData[0]?.userData[0]._id;
  const foodList = useSelector((state) => state.seller.getSellerFoodData);
  useEffect(() => {
    setLoading(true);
    const UserId = userIdbySeller || userId1 || userId;
    setTimeout(() => {
      if (UserId !== undefined) {
        GetFoodBySellerAPI(id);

        GetRecipeByUserAPI(UserId);

        getFoodDiaryByUserAPI(UserId);
      }
    }, 5000);
    setLoading(false);
  }, [id, userId, userId1, userIdbySeller]);
  const recipeList = useSelector(
    (state) => state.recipe.getAllRecipeByUserData
  );
  const diaryList = useSelector(
    (state) => state.foodDiary.getAllFoodDiaryByUserData
  );
  if (loading) {
    return <h1>loading__.....</h1>;
  }
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 4,
  };
  return (
    <div>
      {loading && <h1>loading__.....</h1>}
      {console.log("datacheck", foodList)}
      {/* slider section start */}
      {foodList &&
        foodList.map((item) => {
          if (!!item._id) {
            return (
              <SliderProvider
                food={item?.items}
                sliderSettings={settings}
                title={item?._id?.foodType}
              />
            );
          }
          else if(!!item.categoryName){
            return (
              <SliderProvider
                food={item?.items}
                sliderSettings={settings}
                title={item?.categoryName}
              />
            );
          }
        })}
      <SliderProvider sliderSettings={settings} title={"instant"} />
      {/* slider section end */}
      {/* all food section start */}
      <div>
        <h1> {foodList?.length > 0 && `All Food`}</h1>
        <div className='row'>
          {!!foodList &&
            foodList.map((item, index) => (
              <FoodPresentationCard data={item} index={index} />
            ))}
        </div>
      </div>
      {/* all food section end */}

      {/* recipe section start */}
      <div>
        <h1> {recipeList?.length >= 0 && `All Recipe`}</h1>
        <div className='row all-recipe-container'>
          {recipeList?.length >= 0 &&
            recipeList.map((item, index) => (
              <RecipeCard data={item} index={index} />
            ))}
        </div>
      </div>

      {/* recipe section end */}

      {/* food diary section start */}
      <div>
        <h1>{diaryList?.length > 0 && `All Diary`}</h1>
        <div className='row'>
          {diaryList?.length >= 0 &&
            diaryList.map((item, index) => (
              <DiaryCard data={item} index={index} />
            ))}
        </div>
      </div>

      {/* food diary section end */}
    </div>
  );
};

export default AllFoodSeller;
