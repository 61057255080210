import React from 'react';

const Loader = () => {
    return (
        <>
            <div class="spinner-container">
                <div role="status">
                <span class="loader"></span>
                </div>
            </div>

        </>
    );
};

export default Loader;