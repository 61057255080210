import React, { useEffect } from "react";
import { GetFoodBySellerAPI } from "../../../API/SellerAPI";
import { useSelector } from "react-redux";
import FoodPresentationCard from "../../Common/Cards/FoodPresentation/FoodPresentationCard";
import { useParams } from "react-router-dom";
import { FOOD_TYPE } from "../../../Helper/Food_TYPE";

const InstantFoodSeller = () => {
  let { id } = useParams();
  console.log(id, "hi");
  useEffect(() => {
    GetFoodBySellerAPI(id);
  }, []);
  const foodList = useSelector((state) => state.seller.getSellerFoodData);
  console.log(foodList);
  return (
    <div className="row">
      {foodList &&
        foodList
          .filter((i) => i.foodType === FOOD_TYPE.instant)
          .map((item, index) => (
            <FoodPresentationCard data={item} index={index} />
          ))}
    </div>
  );
};

export default InstantFoodSeller;
