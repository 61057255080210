import React from 'react';
import './sample.css'

const Sample = () => {
    return (
        <>

            {/* <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-6'>
                            <div className='row'>

                                <div className="col-lg-12">
                                    <div className="left-side-title">
                                        <h2>Amazing Dishes</h2>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className='col-lg-12'>
                                            <div className='left-side-gallery'>
                                                <img src="https://images.pexels.com/photos/5745991/pexels-photo-5745991.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" className='img-fluid' />
                                            </div>
                                        </div>


                                        <div className="col-lg-4">
                                            <img src="https://nodeapp.envyfy.com/uploads/Food//jabed/1692102015223-802849584-janice-lin-yUIN4QWKCTw-unsplash.jpeg" alt="" />
                                        </div>
                                        <div className="col-lg-4">
                                            <img src="https://nodeapp.envyfy.com/uploads/Food//jabed/1692102015223-802849584-janice-lin-yUIN4QWKCTw-unsplash.jpeg" alt="" />
                                        </div>
                                        <div className="col-lg-4">
                                            <img src="https://nodeapp.envyfy.com/uploads/Food//jabed/1692102015223-802849584-janice-lin-yUIN4QWKCTw-unsplash.jpeg" alt="" />
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="right-side-title">
                                        <h2>Awesome Dishes</h2>
                                    </div>
                                </div>


                            </div>

                            <div className="row">
                                <div className="col-lg-8">
                                    <img src="https://nodeapp.envyfy.com/uploads/Food//jabed/1692101944555-851264936-pablo-merchan-montes-hyIE90CN6b0-unsplash.jpeg" alt="" />
                                    
                                </div>
                                <div className="col-lg-4">
                                <div className="row">
                                        <div className="col-lg-12">
                                            <img src="https://nodeapp.envyfy.com/uploads/Food//jabed/1692102015223-802849584-janice-lin-yUIN4QWKCTw-unsplash.jpeg" alt="" />
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <img src="https://nodeapp.envyfy.com/uploads/Food//jabed/1692102015223-802849584-janice-lin-yUIN4QWKCTw-unsplash.jpeg" alt="" />
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <img src="https://nodeapp.envyfy.com/uploads/Food//jabed/1692102015223-802849584-janice-lin-yUIN4QWKCTw-unsplash.jpeg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>







                    </div>
                </div>
            </section> */}


            <section className="py-5">
                <div className="container">
                 
                    <div className="row">
                        <div className="col-lg-6">
                        <div className="row">
                        <div className="col-lg-12">
                            <h2>Fat and Oils</h2>
                        </div>
                    </div>
                        <div className='row'>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-12 mt-2">
                                <div className="fat-IMG">
                                    <img src="https://images.pexels.com/photos/3504876/pexels-photo-3504876.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='img-fluid' alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-2">
                                <div className="fat-IMG">
                                    <img src="https://images.pexels.com/photos/3504876/pexels-photo-3504876.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='img-fluid' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-lg-12 mt-2">
                                <div className="fat-IMG2">
                                    <img src="https://images.pexels.com/photos/2586065/pexels-photo-2586065.jpeg?auto=compress&cs=tinysrgb&w=1260&h=250&dpr=1" className='img-fluid' alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-2">
                                <div className="fat-IMG2">
                                    <img src="https://images.pexels.com/photos/2586065/pexels-photo-2586065.jpeg?auto=compress&cs=tinysrgb&w=1260&h=250&dpr=1" className='img-fluid' alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-2">
                                    <div className="fat-IMG2">
                                    <img src="https://images.pexels.com/photos/2586065/pexels-photo-2586065.jpeg?auto=compress&cs=tinysrgb&w=1260&h=250&dpr=1" className='img-fluid' alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="row">
                        <div className="col-lg-12">
                            <h2>Fat and Oils</h2>
                        </div>
                    </div>
                            <div className="row mt-1">
                                <div className="col-lg-6">
                                <div className='rightIMGC1' >
                                    <img src="https://images.pexels.com/photos/15913452/pexels-photo-15913452/free-photo-of-poke-bowl-with-salmon.jpeg?auto=compress&cs=tinysrgb&w=600" className='img-fluid' alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                <div  className='rightIMGC1'>
                                <img src="https://images.pexels.com/photos/7441761/pexels-photo-7441761.jpeg?auto=compress&cs=tinysrgb&w=600" className='img-fluid' alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                <div className='rightBottomIMGC50'>
                                    <img src="https://images.pexels.com/photos/1146760/pexels-photo-1146760.jpeg?auto=compress&cs=tinysrgb&w=600" className='img-fluid' alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                <div className='rightBottomIMGC50'>
                                    <img src="https://images.pexels.com/photos/1146760/pexels-photo-1146760.jpeg?auto=compress&cs=tinysrgb&w=600" className='img-fluid' alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                <div className='rightBottomIMGC50'>
                                    <img src="https://images.pexels.com/photos/1146760/pexels-photo-1146760.jpeg?auto=compress&cs=tinysrgb&w=600" className='img-fluid' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
};

export default Sample;