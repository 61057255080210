import React, { useCallback, useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import CenterSlider from "../Components/ChildComponents/CenterSlider";
import ReactPlayer from "react-player";
import { FaCartPlus } from "react-icons/fa";
import Header from "../Components/Common/Header";
import ProfileFoodGallery from "../Components/ChildComponents/ProfileFoodGallery";
import Footer from "../Components/Common/Footer";
import axios from "axios";
import { BaseURL } from "../Helper/config";
import { Link, Outlet, useParams } from "react-router-dom";
import { GetLimitedSellerAPI, GetSellerAPI } from "../API/SellerAPI";
import { getSellerInfo } from "../Redux/State-slice/SellerSlice";
import { useSelector } from "react-redux";
import { Accordion, Button } from "react-bootstrap";
import KitchenSlider from "./KitchenSlider";

const UpdateSellerProfile = () => {
  let profilePhoto;
  const [recipeData, setRecipeData] = useState({});
  const [foodData, setFoodData] = useState([]);
  // const [sellerData, setSellerData] = useState({});
  const [isSticky, setIsSticky] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let { id } = useParams();
  const getFood = async () => {
    try {
      const response1 = await axios.get(
        `${BaseURL}/get-food-by-seller/${id}/3`
      );
      //console.log((response1);
      if (response1.data.status === "Success") {
        profilePhoto =
          (sellerData?.sellerProfilePhoto &&
            sellerData?.sellerProfilePhoto[0]?.medium?.imageUrl) ||
          "";
        // console.log("as", response1.data.data[0]);
        // setSellerData(response1?.data?.data[0]);
        // callback(response1?.data?.data[0])
        setFoodData(response1?.data?.data[0]?.foodData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    GetSellerAPI(id);
    // getSellerInfo("64da2890ad8bc1bebcc4ee48")
  }, [id]);
  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get(
          `${BaseURL}/get-recipe-by-single-seller/${id}/5`
        );

        if (response.data.status === "Success") {
          setRecipeData(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    getUser();
    getFood();

    //  GetLimitedSellerAPI()

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      //console.log((scrollPosition);
      if (scrollPosition > 1000) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const sellerData = useSelector((state) => state.seller.getSellerInfo);
const userId=sellerData[0]?.userData[0]._id
if (!sellerData) {
  return <>Loading data...</>;
}

return (
  <div className=''>
    {/* <Header /> */}
    <div className='UpdateSellerProfile row g-0'>
      {/* first section start */}
      <div className='col-12 p'>
        <div className='row profile-section gap-2'>
          <div className='col-lg-8 col-md-12'>
            <div className='inner-section'>
              <div className='d-flex align-items-center justify-content-between'>
                <div>
                  {" "}
                  <h1 className='text-uppercase SellerName'>
                    {sellerData[0] &&
                      sellerData[0]?.userData[0] &&
                      sellerData[0]?.userData[0]?.userFullName}
                  </h1>
                </div>
                <div>
                  {/* <h6>
                      <span className='sellerId'> seller id:</span> Something123
                    </h6> */}
                </div>
              </div>

              <h3>{sellerData[0]?.kitchenName}</h3>
              {/* <p>Catering Service: Saiya</p> */}
              <p>I am {sellerData[0]?.pointLocation?.userAddress}</p>
              {/* <p>SellerID:sadiya123</p> */}
              <div className='othersinfo row justify-content-between g-3  p-2'>
                <div className=' col-6'>
                  <div className='msr'>
                    <p className='ms-1 text-white'>My Skill</p>
                  </div>
                  {/* <div className='msr'>
                      <h5 className='ms-1 text-white'>My Skill</h5>
                    </div> */}

                  <div className='row p-2   mt-1'>
                    {sellerData[0] &&
                      sellerData[0].otherFoodSkillsMedia?.length > 0 &&
                      sellerData[0].otherFoodSkillsMedia?.map((item, index) => (
                        <div
                          key={index}
                          className='col s-content d-flex justify-content-center align-items-center me-1 my-1'
                        >
                          <span className='text-nowrap'>{item?.foodType}</span>
                        </div>
                      ))}
                  </div>
                </div>
                <div className='col-6'>
                  <div className='msr'>
                    <p className='ms-1 text-white'>My Restriction</p>
                  </div>

                  <div className='row p-2   mt-1'>
                    {sellerData[0] &&
                      sellerData[0].myRestrictions?.length > 0 &&
                      sellerData[0].myRestrictions?.map((item, index) => (
                        <div
                          key={index}
                          className='col s-content d-flex justify-content-center align-items-center me-1 my-1'
                        >
                          <span className='text-nowrap'>{item}</span>
                        </div>
                        // <div
                        //   key={index}
                        //   className='col-4 s-content d-flex justify-content-center align-items-center '
                        // >
                        //   <span>{`item`}</span>
                        // </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col  '>
            <div className='d-flex align-items-center justify-content-center'>
              <div className='profile-picture'>
                <img
                  alt=''
                  loading='lazy'
                  className='img-fluid '
                  src={sellerData[0]?.sellerProfilePhoto[0]?.medium?.imageUrl}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* first section end */}

      {/* second section start */}
      <div className='col-12 second-section mt-4 shadow-sm'>
        <div className='row gap-1 centerslider-list'>
          <div className='col-6 col-xl-4 col-lg-12 col-md-12 center-block center-slider'>
            {/* <KitchenSlider />
              <CenterSlider /> */}
          </div>
          <div className='col video-player  '>
            <Tabs>
              <div
                className='row  '
                style={{
                  marginRight: "0px",
                  // border: "2px solid #1B6DC1"
                }}
              >
                <div className='col-4 col-xl-4 col-lg-5 col-md-5 center-block center-slider'>
                  <KitchenSlider />
                  {/* <CenterSlider /> */}
                </div>
                <div className='video-section col-xl-8 col-lg-7 col-md-7 row'>
                  <div className='video col-12 col-xl-9 col-md-6 col-sm-12 video-player-item'>
                    <TabPanel style={{ width: "100%", height: "100%" }}>
                      <div className='video'>
                        <ReactPlayer
                          className='react-player'
                          //  playing
                          controls
                          // light={<img className="img-fluid" src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80' alt='Thumbnail' />}
                          url={"https://www.youtube.com/watch?v=GPBe-oYlG1A"}
                          width='100%'
                          height='100%'
                        />
                      </div>
                    </TabPanel>
                    <TabPanel style={{ width: "100%", height: "100%" }}>
                      <div className='video'>
                        <ReactPlayer
                          className='react-player'
                          //  playing
                          controls
                          // light={<img className="img-fluid" src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80' alt='Thumbnail' />}
                          url={"https://fb.watch/qJl4ArYQOp/"}
                          width='100%'
                          height='100%'
                        />
                      </div>
                    </TabPanel>
                    <TabPanel style={{ width: "100%", height: "100%" }}>
                      <div className='video'>
                        <ReactPlayer
                          className='react-player'
                          //  playing
                          controls
                          // light={<img className="img-fluid" src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80' alt='Thumbnail' />}
                          url={"https://www.youtube.com/watch?v=GPBe-oYlG1A"}
                          width='100%'
                          height='100%'
                        />
                      </div>
                    </TabPanel>
                    <TabPanel style={{ width: "100%", height: "100%" }}>
                      <div className='video'>
                        <ReactPlayer
                          className='react-player'
                          //  playing
                          controls
                          // light={<img className="img-fluid" src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80' alt='Thumbnail' />}
                          url={"https://www.youtube.com/watch?v=GPBe-oYlG1A"}
                          width='100%'
                          height='100%'
                        />
                      </div>
                    </TabPanel>
                  </div>
                  <div className='col-12 col-xl-3 col-md-6 col-sm-12 video-list'>
                    <div className='row video-list-item'>
                      <TabList>
                        <Tab className='col-12 video-item shadow-sm w-100'>
                          <div className='thumbContainer row gx-0 gy-2'>
                            <div className='small-video col-12'>
                              <img
                                style={{ height: "100%" }}
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-12'>
                              <p>Lorem ipsum dolor sit amet,</p>
                            </div>
                          </div>
                        </Tab>
                        <Tab className='col-12 video-item shadow-sm w-100'>
                          <div className='thumbContainer row gx-0'>
                            <div className='small-video col-12'>
                              <img
                                style={{ height: "100%" }}
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-12'>
                              <p>Lorem ipsum dolor sit amet,</p>
                            </div>
                          </div>
                        </Tab>
                        <Tab className='col-12 video-item shadow-sm w-100'>
                          <div className='thumbContainer row gx-0'>
                            <div className='small-video col-12  '>
                              <img
                                style={{ height: "100%" }}
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-12'>
                              <p>Lorem ipsum dolor sit amet,</p>
                            </div>
                          </div>
                        </Tab>
                        <Tab className='col-12 video-item shadow-sm w-100'>
                          <div className='thumbContainer row gx-0'>
                            <div className='small-video col-12  '>
                              <img
                                style={{ height: "100%" }}
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-12'>
                              <p>Lorem ipsum dolor sit amet,</p>
                            </div>
                          </div>
                        </Tab>
                        <Tab className='col-12 video-item shadow-sm w-100'>
                          <div className='thumbContainer row gx-0'>
                            <div className='small-video col-12  '>
                              <img
                                style={{ height: "100%" }}
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-12'>
                              <p>Lorem ipsum dolor sit amet,</p>
                            </div>
                          </div>
                        </Tab>
                      </TabList>
                    </div>
                    {/* <div className='row gap-2 '>
                      <TabList>
                        <Tab className='col video-item shadow-sm'>
                          <div className='row d-flex justify-content-around  align-items-center shadow-sm'>
                            <div className='small-video col-4 '>
                              <img
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-8'>
                              <p>
                                Lorem ipsum dolor sit amet, some thing or
                                another
                              </p>
                            </div>
                          </div>
                        </Tab>
                        <Tab className='col video-item shadow-sm'>
                          <div className='row d-flex justify-content-around  align-items-center shadow-sm'>
                            <div className='small-video col-4 '>
                              <img
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-8'>
                              <p>
                                Lorem ipsum dolor sit amet, some thing or
                                another
                              </p>
                            </div>
                          </div>
                        </Tab>
                        <Tab className='col video-item shadow-sm'>
                          <div className='row d-flex justify-content-around  align-items-center shadow-sm'>
                            <div className='small-video col-4 '>
                              <img
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-8'>
                              <p>
                                Lorem ipsum dolor sit amet, some thing or
                                another
                              </p>
                            </div>
                          </div>
                        </Tab>
                        <Tab className='col video-item shadow-sm'>
                          <div className='row d-flex justify-content-around  align-items-center shadow-sm'>
                            <div className='small-video col-4 '>
                              <img
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-8'>
                              <p>
                                Lorem ipsum dolor sit amet, some thing or
                                another
                              </p>
                            </div>
                          </div>
                        </Tab>
                        <Tab className='col video-item shadow-sm'>
                          <div className='row d-flex justify-content-around  align-items-center shadow-sm'>
                            <div className='small-video col-4 '>
                              <img
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-8'>
                              <p>
                                Lorem ipsum dolor sit amet, some thing or
                                another
                              </p>
                            </div>
                          </div>
                        </Tab>
                        <Tab className='col video-item shadow-sm'>
                          <div className='row d-flex justify-content-around  align-items-center shadow-sm'>
                            <div className='small-video col-4 '>
                              <img
                                className='img-fluid'
                                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                                alt='Thumbnail'
                              />
                            </div>
                            <div className='text-dark col-8'>
                              <p>
                                Lorem ipsum dolor sit amet, some thing or
                                another
                              </p>
                            </div>
                          </div>
                        </Tab>
                      </TabList>
                    </div> */}
                  </div>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
      <div className='col-12 another-details'>
        <div className='another-details-inner'>
          <div className='d-flex ms-2'>
            <div className='me-2 p-3'>{`Total Food :${
              foodData?.length || 0
            }`}</div>
            <div className=' p-3' style={{ borderLeft: "1px solid white" }}>
              Review{" "}
            </div>
          </div>
          <div className='d-flex'>
            <div className='col'>
              <button className='wish'>
                <Link to={`/wish-order?sellerId=${id}`} state={sellerData}>
                  Create Wish Order
                </Link>
              </button>
            </div>
            <div className='col'>
              <button className='catering'>
                {" "}
                <Link to={`/catering-order`} state={sellerData}>
                  Create Catering Order
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* second section end */}

      {/* menubar start */}
      {/* className={isSticky ? "sticky MenuBar col-12" : "MenuBar col-12"} */}

      {/* <div
          className={`sticky-item MenuBar col-12  MenuBar col-12  ${
            isSticky ? "sticky " : ""
          }`}
        >
          <nav class='menu-bar'>
            <ul>
              <li>
                <a href='#'>My Profile</a>
              </li>
              <li>
                <a href='#'>Kacchi Biryani </a>
              </li>
              <li>
                <a href='#'>Panta Ilish</a>
                <ul class='submenu'>
                  <li>
                    <a href='#'>Panta Ilish</a>
                  </li>
                  <li>
                    <a href='#'>Panta Ilish </a>
                  </li>
                  <li>
                    <a href='#'>Panta Ilish</a>
                  </li>
                  <li>
                    <a href='#'>Panta Ilish</a>
                  </li>
                  <li>
                    <a href='#'>Panta Ilish Specials</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href='#'>Vuna Khichuri</a>
              </li>
              <li>
                <a href='#'>Morog Polao</a>
              </li>
              <li>
                <a href='#'>Reservations</a>
              </li>
              <li>
                <a href='#'>Grill Chicken</a>
              </li>
              <li>
                <a href='#'>Haleem</a>
              </li>
              <li>
                <a href='#'>Seekh Kebab</a>
              </li>
            </ul>
          </nav>
        </div> */}

      {/* menuvbar end */}
      <div className='col-12 food-container'>
        <div className='row'>
          <div className='col-xl-2 col-md-3'>
            {windowWidth <= 576 && (
              <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>Food Type</Accordion.Header>
                  <Accordion.Body>
                    <div className=' asidebar  bg-white shadow-lg'>
                      <Link
                        to={`/SellerProfile/${id}?userId=${sellerData[0]?.userData[0]._id}`}
                      >
                        All
                      </Link>
                      <Link
                        to={`/SellerProfile/${id}/instant?userId=${sellerData[0]?.userData[0]._id}`}
                      >
                        INSTANT
                      </Link>
                      <Link
                        to={`/SellerProfile/${id}/wish-order?userId=${sellerData[0]?.userData[0]._id}`}
                      >
                        wish food
                      </Link>
                      <Link
                        to={`/SellerProfile/${id}/catering?userId=${sellerData[0]?.userData[0]._id}`}
                      >
                        CATERING
                      </Link>
                      <Link
                        to={`/SellerProfile/${id}/pre-order?userId=${sellerData[0]?.userData[0]._id}`}
                      >
                        pre order
                      </Link>
                      <Link
                        to={`/SellerProfile/${id}/recipe?userId=${sellerData[0]?.userData[0]._id}`}
                      >
                        recipe
                      </Link>
                      <Link
                        to={`/SellerProfile/${id}/diary?userId=${sellerData[0]?.userData[0]._id}`}
                      >
                        my diary
                      </Link>
                      <Link
                        to={`/SellerProfile/${id}/experimental?userId=${sellerData[0]?.userData[0]._id}`}
                        state={sellerData[0]?.myExperimentalFoodMedia}
                      >
                        EXPERIMENTAL
                      </Link>
                      <Link
                        to={`/SellerProfile/${id}/amazing-dish?userId=${sellerData[0]?.userData[0]._id}`}
                        state={sellerData[0]?.dishesMedia}
                      >
                        AMAZING food
                      </Link>
                      <Link
                        to={`/SellerProfile/${id}/near-seller?userId=${sellerData[0]?.userData[0]._id}`}
                      >
                        near by seller
                      </Link>
                      <Link
                        to={`/SellerProfile/${id}/review?userId=${sellerData[0]?.userData[0]._id}`}
                      >
                        review
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
            {windowWidth > 576 && (
              <div className=' asidebar  bg-white shadow-lg'>
                <Link
                  to={`/SellerProfile/${id}?userId=${sellerData[0]?.userData[0]._id}`}
                >
                  All
                </Link>
                <Link
                  to={`/SellerProfile/${id}/instant?userId=${sellerData[0]?.userData[0]._id}`}
                >
                  INSTANT
                </Link>

                <Link
                  to={`/SellerProfile/${id}/catering?userId=${sellerData[0]?.userData[0]._id}`}
                >
                  CATERING
                </Link>
                <Link
                  to={`/SellerProfile/${id}/pre-order?userId=${sellerData[0]?.userData[0]._id}`}
                >
                  pre order
                </Link>
                <Link
                  to={`/SellerProfile/${id}/recipe?userId=${sellerData[0]?.userData[0]._id}`}
                >
                  recipe
                </Link>
                <Link
                  to={`/SellerProfile/${id}/diary?userId=${sellerData[0]?.userData[0]._id}`}
                >
                  my diary
                </Link>
                <Link
                  to={`/SellerProfile/${id}/experimental?userId=${sellerData[0]?.userData[0]._id}`}
                  state={sellerData[0]?.myExperimentalFoodMedia}
                >
                  EXPERIMENTAL
                </Link>
                <Link
                  to={`/SellerProfile/${id}/amazing-dish?userId=${sellerData[0]?.userData[0]._id}`}
                  state={sellerData[0]?.dishesMedia}
                >
                  AMAZING food
                </Link>
                <Link
                  to={`/SellerProfile/${id}/near-seller?userId=${sellerData[0]?.userData[0]._id}`}
                >
                  near by seller
                </Link>
                <Link
                  to={`/SellerProfile/${id}/review?userId=${sellerData[0]?.userData[0]._id}`}
                >
                  review
                </Link>
              </div>
            )}
          </div>

          {/* <div className="col-10">
              <div className="row">
                <div className="col-12"> <p>Pick a delivery date</p></div>
                <div className="col-12"> <hr /></div>
                <div className="col-12"></div>
              </div>
            </div> */}
          <div className='col-10 col-xl-10 col-lg-9 col-md-9 col-sm-9'>
            <div className='row'>
              <div className='col-12 day-container-children'>
                <div className='row p-3'>
                  <div className='col-12'>
                    <p>Pick a delivery date</p>
                  </div>
                  <div className='col-12'>
                    <hr style={{ margin: "2px" }} />
                  </div>
                  <div className='col-12 '>
                    <div className='row '>
                      <div className='col-2 border day'>
                        <div
                          className='d-flex flex-column justify-content-start
                           text-center'
                        >
                          <span className='sd-bold pick-day'>SUN</span>
                          <span>Jan 26</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='col-12 col-xl-12 all-items-container'
                style={{ marginTop: "14px" }}
              >
                <Outlet context={{ userId }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ProfileFoodGallery /> */}
    </div>
    {/* <Footer /> */}
  </div>
);
};

export default UpdateSellerProfile;