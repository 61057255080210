import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { GetFoodBySellerAPI } from "../../../API/SellerAPI";
import FoodPresentationCard from "../../Common/Cards/FoodPresentation/FoodPresentationCard";
import { useParams } from "react-router-dom";
import { FOOD_TYPE } from "../../../Helper/Food_TYPE";

const CateringFoodSeller = () => {
  let { id } = useParams();
  useEffect(() => {
    GetFoodBySellerAPI(id);
  }, []);
  const foodList = useSelector((state) => state.seller.getSellerFoodData);
//   console.log(foodList.length);
  return (
    <div className='row'>
      {foodList?.filter((i) => i.foodType === FOOD_TYPE.catering).length <= 0 && (
        <h1>no food found</h1>
      )}
      {foodList &&
        foodList
          ?.filter((i) => i.foodType === FOOD_TYPE.catering)
          ?.map((item, index) => (
            <FoodPresentationCard data={item} index={index} />
          ))}
    </div>
  );
};

export default CateringFoodSeller;
